import { Storage } from "@ionic/storage"
import {
  formatTime,
  formatTimeRP,
} from "../utilities/utilities"


export class WordsService {
  static MAX_HISTORY = 100
  storage

  constructor() {
    this.storage = new Storage()
    this.storage.create()
  }

  async addToWords(video, sourceLang, sourceText, sourceTile, targetLang, targetText, targetTile) {
    let words = (await this.storage.get("recentwords")) || []
    
    // Initialize userViews count
    let userViewsCount = 1;

    // Find the old entry of the same vocabulary word/phrase and get its userViews count
    const oldEntryIndex = words.findIndex(
      (wordItem) =>
      wordItem.sourceText === sourceText
    );

    if (oldEntryIndex !== -1) {
      userViewsCount = (words[oldEntryIndex].userViews || 0) + 1;
      words.splice(oldEntryIndex, 1); // Remove the old entry
    }

      if (words.length >= WordsService.MAX_HISTORY) {
        // remove the oldest entry if MAX_HISTORY is exceeded
        words.pop(); // Use pop() to remove the last (oldest) item
      }

      const videoData = {
        id: video.id?.videoId || video.id || video.videoId,
        title: video.snippet?.title || video.title,
        channelTitle: video.snippet?.channelTitle || video.channelTitle,
        channelId: video.snippet?.channelId || video.channelId,
        description: video.snippet?.description || video.description,
        viewCount: video.viewCount || 0,
        publishDate: video.publishDate || "",
        lengthText: video.lengthText || formatTimeRP(parseInt(video.lengthSeconds)) || "",
        userViews: userViewsCount,
        thumbnail:
          video.snippet?.thumbnails?.medium?.url || video.thumbnail?.[3]?.url || video.thumbnail?.[0]?.url,
        sourceText: sourceText,
        sourceLanguage:sourceLang,
        targetText: targetText,
        targetLanguage: targetLang,
        sourceTileText: sourceTile.t,
        sourceTileIndex: sourceTile.i,
        targetTileText: targetTile.t
      }

      // Add the new entry to the history
      // history.push(videoData)
      words.unshift(videoData) //add to the front of the list, so most recent on top.

      await this.storage.set("recentwords", words)
  }

  async removeFromHistory(videoId) {
    let words = (await this.storage.get("recentwords")) || []

    words = words.filter((video) => video.id !== videoId)
    await this.storage.set("recentwords", words)
  }

  async getWords() {
    const words = await this.storage.get("recentwords")
    return words || []
  }

  async clearWords() {
    await this.storage.remove("recentwords")
  }

  //Adding to Fashcards deck for spaced repetition (SR)
  async addToDeck(wordData) {
    let deck = (await this.storage.get("srvocab")) || []

    // Check if word already exists
    const wordExists = deck.some(
      (wordItem) => wordItem.sourceText === wordData.sourceText
    )

    if (wordExists) {
      return false // Word already exists
    } else {
      // Calculate review dates based on intervals, an array of dates
      const intervals = [0, 1, 3, 8, 13, 21, 54, 144] // days from current date
      const reviewDates = intervals.map(days => {
        const reviewDate = new Date()
        reviewDate.setDate(reviewDate.getDate() + days)
        return reviewDate.toISOString()
      })

      const enhancedWordData = {
        ...wordData,
        addedDate: new Date().toISOString(), // Current date in ISO format
        reviewCount: 0, // Initial review count
        reviewDates: reviewDates, //array for future review dates
        notes: "", // Empty string for notes
      }
      deck.unshift(enhancedWordData)
      await this.storage.set("srvocab", deck)
      return true // Word added successfully
    }
  }

  //check if a word is in the deck
  async isWordInDeck(sourceText) {
    let deck = (await this.storage.get("srvocab")) || []
    return deck.some((wordItem) => wordItem.sourceText === sourceText)
  }

  //get all sourceText from the srvocab deck
  async getDeckWords() {
    let deck = (await this.storage.get("srvocab")) || []
    return deck.map((wordItem) => wordItem.sourceText)
  }

    // Fetch all words in srvocab (Active words)
    async getActiveWords() {
      let deck = (await this.storage.get("srvocab")) || []
      return deck
    }

    // Fetch words ready for review
  async getReviewWords() {
    let deck = (await this.storage.get("srvocab")) || []
    const today = new Date().toISOString()

    return deck.filter(word => {
      const { reviewCount, reviewDates } = word
      // Get the next review date based on reviewCount
      const nextReviewDate = reviewDates[reviewCount]
      if (!nextReviewDate) {
        return false // No more review dates
      }
      // If the next review date is on or before today, include the word
      return nextReviewDate <= today
    })
  }

  // Fetch words from srvocabdone (Done words)
  async getDoneWords() {
    let doneDeck = (await this.storage.get("srvocabdone")) || []
    return doneDeck
  }

  // Move word from srvocab to srvocabdone
  async moveToDone(sourceText) {
    let activeDeck = (await this.storage.get("srvocab")) || []
    let doneDeck = (await this.storage.get("srvocabdone")) || []

    const wordIndex = activeDeck.findIndex((word) => word.sourceText === sourceText)
    if (wordIndex !== -1) {
      const [word] = activeDeck.splice(wordIndex, 1)
      doneDeck.unshift(word)
      await this.storage.set("srvocab", activeDeck)
      await this.storage.set("srvocabdone", doneDeck)
      return true
    } else {
      return false // Word not found
    }
  }

  // Move word from srvocabdone back to srvocab
  async moveToActive(sourceText) {
    let activeDeck = (await this.storage.get("srvocab")) || []
    let doneDeck = (await this.storage.get("srvocabdone")) || []

    const wordIndex = doneDeck.findIndex((word) => word.sourceText === sourceText)
    if (wordIndex !== -1) {
      const [word] = doneDeck.splice(wordIndex, 1)
      activeDeck.unshift(word)
      await this.storage.set("srvocab", activeDeck)
      await this.storage.set("srvocabdone", doneDeck)
      return true
    } else {
      return false // Word not found
    }
  }

  //update reviewCount and reviewDates after review - progresses the word thru SR
  async markWordAsReviewed(sourceText) {
    let deck = (await this.storage.get("srvocab")) || []
    const wordIndex = deck.findIndex((word) => word.sourceText === sourceText)

    if (wordIndex !== -1) {
      const word = deck[wordIndex]
      word.reviewCount += 1

      // Check if reviewCount exceeds reviewDates length
      if (word.reviewCount >= word.reviewDates.length) {
        // Move word to srvocabdone
        await this.moveToDone(sourceText)
      } else {
        // Update the word in the deck
        deck[wordIndex] = word
        await this.storage.set("srvocab", deck)
      }
      return true
    } else {
      return false // Word not found
    }
  }


  // Increment reviewCount
  async incrementReviewCount(sourceText) {
    let deck = (await this.storage.get("srvocab")) || []
    const wordIndex = deck.findIndex((word) => word.sourceText === sourceText)

    if (wordIndex !== -1) {
      const word = deck[wordIndex]
      word.reviewCount += 1

      // Check if reviewCount exceeds max index
      if (word.reviewCount > word.reviewDates.length - 1) {
        // Move word to srvocabdone
        await this.moveToDone(sourceText)
      } else {
        // Update the word in the deck
        deck[wordIndex] = word
        await this.storage.set("srvocab", deck)
      }
      return true
    } else {
      return false // Word not found
    }
  }

  // Decrement reviewCount
  async decrementReviewCount(sourceText) {
    let deck = (await this.storage.get("srvocab")) || []
    const wordIndex = deck.findIndex((word) => word.sourceText === sourceText)

    if (wordIndex !== -1) {
      const word = deck[wordIndex]
      if (word.reviewCount > 0) {
        word.reviewCount -= 1
        // Update the word in the deck
        deck[wordIndex] = word
        await this.storage.set("srvocab", deck)
        return true
      } else {
        return false // reviewCount is already at minimum
      }
    } else {
      return false // Word not found
    }
  }

  // Move word from srvocabdone back to srvocab with reviewCount reset to 0
  // async moveToActiveFromDone(sourceText) {
  //   let activeDeck = (await this.storage.get("srvocab")) || []
  //   let doneDeck = (await this.storage.get("srvocabdone")) || []

  //   const wordIndex = doneDeck.findIndex((word) => word.sourceText === sourceText)
  //   if (wordIndex !== -1) {
  //     const [word] = doneDeck.splice(wordIndex, 1)
  //     // Reset reviewCount and other fields as needed
  //     word.reviewCount = 0
  //     // Recalculate reviewDates starting from today
  //     const intervals = [0, 1, 2, 3, 5, 8, 21, 54, 144]
  //     const reviewDates = intervals.map(days => {
  //       const reviewDate = new Date()
  //       reviewDate.setDate(reviewDate.getDate() + days)
  //       return reviewDate.toISOString()
  //     })
  //     word.reviewDates = reviewDates
  //     word.addedDate = new Date().toISOString()

  //     activeDeck.unshift(word)
  //     await this.storage.set("srvocab", activeDeck)
  //     await this.storage.set("srvocabdone", doneDeck)
  //     return true
  //   } else {
  //     return false // Word not found in done deck
  //   }
  // }
// WordsService.js

// Move word from srvocabdone back to srvocab with reviewCount reset to 0
async moveToActiveFromDone(sourceText) {
  let activeDeck = (await this.storage.get("srvocab")) || []
  let doneDeck = (await this.storage.get("srvocabdone")) || []

  // Make copies to avoid direct mutation
  activeDeck = [...activeDeck]
  doneDeck = [...doneDeck]

  const wordIndex = doneDeck.findIndex((word) => word.sourceText === sourceText)
  if (wordIndex !== -1) {
    // Remove the word from doneDeck
    const [word] = doneDeck.splice(wordIndex, 1)

    // Reset reviewCount and other fields as needed
    word.reviewCount = 0
    // Recalculate reviewDates starting from today
    const intervals = [0, 1, 2, 3, 5, 8, 21, 54, 144]
    const reviewDates = intervals.map(days => {
      const reviewDate = new Date()
      reviewDate.setDate(reviewDate.getDate() + days)
      return reviewDate.toISOString()
    })
    word.reviewDates = reviewDates
    word.addedDate = new Date().toISOString()

    // Add the word back to activeDeck
    activeDeck.unshift(word)

    // Save the updated decks back to storage
    await this.storage.set("srvocab", activeDeck)
    await this.storage.set("srvocabdone", doneDeck)

    return true
  } else {
    return false // Word not found in done deck
  }
}

// Delete a word from srvocabdone
async deleteFromDone(sourceText) {
  let doneDeck = (await this.storage.get("srvocabdone")) || []

  const wordIndex = doneDeck.findIndex((word) => word.sourceText === sourceText)
  if (wordIndex !== -1) {
    // Remove the word from doneDeck
    doneDeck.splice(wordIndex, 1)

    // Save the updated deck back to storage
    await this.storage.set("srvocabdone", doneDeck)
    return true
  } else {
    return false // Word not found
  }
}

// async updateWordText(originalSourceText, newText, field) {
//   let deck = (await this.storage.get("srvocab")) || []
//   let doneDeck = (await this.storage.get("srvocabdone")) || []

//   // Try to find the word in active deck
//   let wordIndex = deck.findIndex((word) => word.sourceText === originalSourceText)
//   if (wordIndex !== -1) {
//     deck[wordIndex][field] = newText
//     await this.storage.set("srvocab", deck)
//     return true
//   }

//   // Try to find the word in done deck
//   wordIndex = doneDeck.findIndex((word) => word.sourceText === originalSourceText)
//   if (wordIndex !== -1) {
//     doneDeck[wordIndex][field] = newText
//     await this.storage.set("srvocabdone", doneDeck)
//     return true
//   }

//   return false // Word not found
// }
// WordsService.js

async updateWordText(originalSourceText, newText, field) {
  let deck = (await this.storage.get("srvocab")) || []
  let doneDeck = (await this.storage.get("srvocabdone")) || []

  // Try to find the word in the active deck
  let wordIndex = deck.findIndex((word) => word.sourceText === originalSourceText)
  if (wordIndex !== -1) {
    // Update the specified field
    deck[wordIndex][field] = newText

    // Handle sourceText change
    if (field === 'sourceText') {
      // Update sourceText in the word object
      deck[wordIndex].sourceText = newText
    }

    await this.storage.set("srvocab", deck)
    return true
  }

  // Try to find the word in the done deck
  wordIndex = doneDeck.findIndex((word) => word.sourceText === originalSourceText)
  if (wordIndex !== -1) {
    // Update the specified field
    doneDeck[wordIndex][field] = newText

    // Handle sourceText change
    if (field === 'sourceText') {
      // Update sourceText in the word object
      doneDeck[wordIndex].sourceText = newText
    }

    await this.storage.set("srvocabdone", doneDeck)
    return true
  }

  return false // Word not found
}





}

const wordsService = new WordsService()
export default wordsService
