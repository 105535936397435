// JMR Vocabulary page, where flashcard decks are kept

import React, { useState, useEffect } from "react"
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButtons,
  IonMenuButton,
  IonItem,
  IonList,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonCardTitle,
  IonIcon,

} from "@ionic/react"
import { Storage } from '@ionic/storage';

import wordsService from "../utils/WordsService"
import VideoCardCompactWord from "../components/VideoCard/VideoCardCompactWord"
import {newspaper,heart, fileTrayStacked,albums,tv} from "ionicons/icons"
import Confetti from 'react-confetti'
import { AnimatePresence, motion } from 'framer-motion';


type SegmentOption = 'review' | 'active' | 'done'

interface WordsVideo {
  id: string
  title: string
  channelTitle: string
  description: string
  thumbnail: string
  channelId: string
  sourceLanguage: string
  sourceText: string
  sourceTile: string
  sourceTileIndex: number
  targetLanguage: string
  targetText: string
  targetTile: string
  addedDate: string
  reviewCount: number
  reviewDates: string[]
  notes: string
}

const storage = new Storage();
const storagePromise = storage.create();

const FlashCardPage: React.FC = () => {
  const [activeSegment, setActiveSegment] = useState<SegmentOption>('review')
  const [reviewWords, setReviewWords] = useState<WordsVideo[]>([])
  const [activeWords, setActiveWords] = useState<WordsVideo[]>([])
  const [doneWords, setDoneWords] = useState<WordsVideo[]>([])
//   const [deckWords, setDeckWords] = useState<Set<string>>(new Set())
  const [deckWords, setDeckWords] = useState<string[]>([])
  const [dataVersion, setDataVersion] = useState(0)
  const [showConfetti, setShowConfetti] = useState(false)
  const [previousReviewWordsLength, setPreviousReviewWordsLength] = useState(reviewWords.length)
  const [cardsReviewedToday, setCardsReviewedToday] = useState(0);


  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (activeSegment === 'review' && reviewWords.length === 0 && previousReviewWordsLength > 0 && !showConfetti) {
      setShowConfetti(true)
      setTimeout(() => {
        setShowConfetti(false)
      }, 5000)
    } else if (reviewWords.length > 0 && showConfetti) {
      setShowConfetti(false)
    }
    setPreviousReviewWordsLength(reviewWords.length)
  }, [activeSegment, reviewWords, showConfetti])

  //get and save number of reviewed cards
  useEffect(() => {
  const initStorage = async () => {
    await storagePromise;
    await storage.create();
    const today = new Date().toDateString();
    const storedDate = await storage.get('cardsReviewedDate');
    if (storedDate !== today) {
      // New day, reset count
      await storage.set('cardsReviewedToday', 0);
      await storage.set('cardsReviewedDate', today);
      setCardsReviewedToday(0);
    } else {
      // Same day, get current count
      const count = (await storage.get('cardsReviewedToday')) || 0;
      setCardsReviewedToday(count);
    }
  };
  initStorage();
}, []);




  //get word lists from wordService
  const fetchData = async () => {
    // Fetch review words
    const review = await wordsService.getReviewWords()
    setReviewWords(review)

    // Fetch active words
    const active = await wordsService.getActiveWords()
    setActiveWords(active)

    // Fetch done words
    const done = await wordsService.getDoneWords()
    setDoneWords(done)

    // Set deckWords for consistency with VideoCardCompactWord component
    const deck = await wordsService.getDeckWords()
    // setDeckWords(new Set(deck))
    setDeckWords(deck)
  }

  // Function to update deckWords when a word is added back to active
//   const updateDeckWords = (newWord: string) => {
//     setDeckWords((prevDeckWords) => new Set(prevDeckWords).add(newWord))
//   }
const updateDeckWords = (newWord: string) => {
    setDeckWords((prevDeckWords) => {
      if (!prevDeckWords.includes(newWord)) {
        return [...prevDeckWords, newWord]
      } else {
        return prevDeckWords
      }
    })
  }

  const handleSegmentChange = (value: string | undefined) => {
    if (value === 'review' || value === 'active' || value === 'done') {
      setActiveSegment(value as SegmentOption)
    }
  }
  
//   const handleWordUpdated = async () => {
//     // Refresh the data
//     await fetchData()
//   }
//   const handleWordUpdated = async () => {
//     await fetchData()
//     setDataVersion(prev => prev + 1)
//   }
const handleWordUpdated = async () => {
    await storagePromise;
    // Increment the daily count
    const today = new Date().toDateString();
    const storedDate = await storage.get('cardsReviewedDate');
    if (storedDate !== today) {
      // New day, reset count
      await storage.set('cardsReviewedToday', 1);
      await storage.set('cardsReviewedDate', today);
      setCardsReviewedToday(1);
    } else {
      // Same day, increment count
      let count = (await storage.get('cardsReviewedToday')) || 0;
      count += 1;
      await storage.set('cardsReviewedToday', count);
      setCardsReviewedToday(count);
    }
  
    // Existing code to refresh data
    await fetchData();
    setDataVersion((prev) => prev + 1);
  };
  

  const renderWordsList = (words: WordsVideo[], segment: SegmentOption) => {
    return words.map((video) => (
        <motion.div
        key={video.sourceText}
        layout
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 100 }}
        transition={{ duration: 0.5 }}
      >
      <VideoCardCompactWord
        video={video}
        key={`${video.sourceText}`}
        deckWords={deckWords}
        updateDeckWords={updateDeckWords}
        onWordUpdated={handleWordUpdated}
        showProgress={segment !== 'done'} // Hide progress in 'done'
        showActions={segment !== 'done'}  // Hide actions in 'done'
        isInDoneSegment={segment === 'done'} // Indicate if in 'done' segment
      />
      </motion.div>
    ))
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color="primary" />
          </IonButtons>
          <IonTitle>Vocabulary</IonTitle>
        </IonToolbar>
        <IonToolbar>
        <IonSegment value={activeSegment} onIonChange={(e) => handleSegmentChange(e.detail.value)}>
            <IonSegmentButton value="review">
              <IonLabel>Review</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="active">
              <IonLabel>Active</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="done">
              <IonLabel>Done</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="compact-list-padding">
        {showConfetti && <Confetti />}
        {/* {activeSegment === 'review' && renderWordsList(reviewWords, 'review')}
        {activeSegment === 'active' && renderWordsList(activeWords, 'active')}
        {activeSegment === 'done' && renderWordsList(doneWords, 'done')} */}
        {activeSegment === 'review' && (
          <>
            {/* Metrics Display for Review Segment */}
            {/* <IonItem>
              <IonLabel>Cards To Review: {reviewWords.length}</IonLabel>
            </IonItem> */}


<IonRow>
                      {/* SEGMENT 1 */}
          {/* First Card */}
          <IonCol size="12" size-md="6">
                  <IonCard color="warning">
                    <IonGrid>
                      <IonRow>
                        {/* Left Column for IonCardTitle */}
                        <IonCol size="3" className="avatar-column">
                          <IonCardTitle>{reviewWords.length}</IonCardTitle>
                        </IonCol>

                        {/* Right Column for Subtitle and Content */}
                        <IonCol size="9">
                        <IonCardTitle style={{ fontSize: '1.6em' }}>Cards To Review</IonCardTitle>
                        
                          <IonIcon
                            color="primary"
                                icon={albums}
                            size = "large"
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
              </IonCol>


              {/* Second Card */}
              <IonCol size="12" size-md="6">
                  <IonCard color="warning">
                    <IonGrid>
                      <IonRow>
                        {/* Left Column for IonCardTitle */}
                        <IonCol size="3" className="avatar-column">
                          <IonCardTitle>{cardsReviewedToday}</IonCardTitle>
                        </IonCol>

                        {/* Right Column for Subtitle and Content */}
                        <IonCol size="9">
                        <IonCardTitle style={{ fontSize: '1.6em' }}>Reviewed Today</IonCardTitle>
                          
                          <IonIcon
                            color="medium"
                                icon={fileTrayStacked}
                            size = "large"
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
              </IonCol>
        </IonRow>




            {/* List of Words */}
            <AnimatePresence>
  {renderWordsList(reviewWords, 'review')}
</AnimatePresence>

          </>
        )}
        {activeSegment === 'active' && (
          <>
            {/* Metrics Display for Active Segment */}
            <IonItem>
              <IonLabel>Total Cards In Review Process: {activeWords.length}</IonLabel>
            </IonItem>
            {/* List of Words */}
            <AnimatePresence>
            {renderWordsList(activeWords, 'active')}
            </AnimatePresence>
          </>
        )}
        {activeSegment === 'done' && (
          <>
            {/* Metrics Display for Done Segment */}
            <IonItem>
              <IonLabel>Total Completed: {doneWords.length}</IonLabel>
            </IonItem>
            {/* List of Words */}
            {renderWordsList(doneWords, 'done')}
          </>
        )}
      </IonContent>
    </IonPage>
  )
}

export default FlashCardPage
