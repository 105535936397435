import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API,
  authDomain: process.env.REACT_APP_FB_AUTHDOM,
  projectId: process.env.REACT_APP_FB_PROJ,
  storageBucket: process.env.REACT_APP_FB_STORB,
  messagingSenderId: process.env.REACT_APP_FB_MSGSEND,
  appId: process.env.REACT_APP_FB_APP,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export default app